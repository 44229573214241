import React, { useState, useEffect } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import { Link } from 'react-router-dom'
import ResponsivePagination from 'react-responsive-pagination';
import Oval from '../Loader/CircleLoade';
import longarrow from '../../../assets/img/longarrow.svg'
import * as Constants from "../../../Constants/index"
import axios from 'axios'
import swal from 'sweetalert'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { saveAs } from 'file-saver';
import { useFormik } from 'formik';
export default function RedemptionReport() {
    const navigate = useNavigate()
    const userType = localStorage.getItem('user_type')
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState(null)
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(10)
    const [filterData, setFilterData] = useState({})


    const searchFormik = useFormik({
        initialValues: {
            status: '',
            startDate: '',
            endDate: ''
        },
        validate: (data) => {
            let errors = {}
            if (data.startDate === '' && data.endDate !== '') {
                errors.startDate = 'Please select date'
            }
            if (data.startDate !== '' && data.endDate === '') {
                errors.endDate = 'Please select date'
            }
            if (data.startDate && data.endDate && data.startDate > data.endDate) {
                errors.endDate = 'Please select date'
            }
            if (data.startDate && data.endDate && data.startDate > data.endDate) {
                errors.endDate = 'End date should be greater than start date'
            }
            // if (data.startDate && data.endDate) {
            //     const maxEndDate = new Date(data.startDate)
            //     maxEndDate.setDate(maxEndDate.getDate() + 30) // Adjust the range as needed (e.g., 31 days)

            //     if (data.endDate > maxEndDate) {
            //         errors.endDate = 'End date should be within 31 days from the start date.'
            //     }
            // }
            return errors
        },
        onSubmit: (data) => {
            console.log('data', data)
            setCurrentPage(1)
            getReportData(data, 1)
            setFilterData(data)
        },
    })
    const isSearchFormFieldValid = (name) => !!(searchFormik.touched[name] && searchFormik.errors[name])
    const getSearchFormErrorMessage = (name) => {
        return isSearchFormFieldValid(name) && <small className="report-error-field">{searchFormik.errors[name]}</small>
    }



    const getReportData = async (data, pageNum) => {
        setLoading(true);
        if (!token) {
            navigate('/')
        } else {
            const searchStatus = data.status === '' ? '' : `status=${data.status}&`
            let stDate
            let endDate

            var momentObj1 = moment(data.startDate)
            var momentObj2 = moment(data.endDate)
            if (data.startDate && data.endDate) {
                var startFormattedDate = momentObj1.format('YYYY-MM-DD HH:mm:ss')
                var startFormattedDate4 = momentObj2.format('YYYY-MM-DD 23:59:59')
                stDate = `start=${startFormattedDate}&`
                endDate = `end=${startFormattedDate4}&`
            } else {
                stDate = ''
                endDate = ''
            }
            let endpoint
            if (data.status !== '') {
                endpoint = `reports/redemptionList/list?${searchStatus}sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${pageNum}`
            }
            if (data.status !== '' && data.startDate !== '' && data.endDate !== '') {
                endpoint = `reports/redemptionList/list?${searchStatus}${stDate}${endDate}sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${pageNum}`
            }
            if (data.status === '' && data.startDate !== '' && data.endDate !== '') {
                endpoint = `reports/redemptionList/list?${stDate}${endDate}sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${pageNum}`
            }
            await axios.get(Constants.baseUrl + `${endpoint}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                }
            }).then((res) => {
                const dt = res.data
                if (dt.result === 'success') {
                    console.log("114", dt.output.results);
                    setReportData(dt?.output?.results)
                    setTotalPages(dt?.output?.totalPages)
                    setLoading(false)
                } if (dt.result === 'error') {
                    setReportData(null)
                    setTotalPages(0)
                    setLoading(false)
                }
            }).catch((err) => {
                setLoading(false)
            })
        }
    }
    const download = (data) => {
        setLoading(true)
        try {
            const searchStatus = data.status === '' ? '' : `status=${data.status}`
            let stDate
            let endDate

            var momentObj1 = moment(data.startDate)
            var momentObj2 = moment(data.endDate)
            if (data.startDate && data.endDate) {
                var startFormattedDate = momentObj1.format('YYYY-MM-DD HH:mm:ss')
                var startFormattedDate4 = momentObj2.format('YYYY-MM-DD 23:59:59')
                stDate = `start=${startFormattedDate}`
                endDate = `&end=${startFormattedDate4}`
            } else {
                stDate = ''
                endDate = ''
            }
            let endpoint
            if (data.status !== '') {
                endpoint = `reports/download/redeemreport?${searchStatus}`
            }
            if (data.status !== '' && data.startDate !== '' && data.endDate !== '') {
                endpoint = `reports/download/redeemreport?${searchStatus}&${stDate}${endDate}`
            }
            if (data.status === '' && data.startDate !== '' && data.endDate !== '') {
                endpoint = `reports/download/redeemreport?${stDate}${endDate}`
            }
            axios.get(Constants.baseUrl + `${endpoint}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
                responseType: 'blob', // Set the responseType to 'blob'
            }).then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    const fileData = new Blob([res.data], { type: 'text/csv' });
                    saveAs(fileData, 'redeemreport.csv');
                } else {
                    swal({
                        text: res.data.msg,
                        // icon: 'error',
                        timer: 2000,
                        buttons: false,
                    })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    const areAllValuesBlank = () => {
        const { status, startDate, endDate } = searchFormik.values
        return (
            !status && !startDate && !endDate
        )
    }

    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">Redemption Report  </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <form onSubmit={searchFormik.handleSubmit}>
                                    <div className="row clearfix mb-15 callwell">
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <DatePicker
                                                id="startDate"
                                                selected={searchFormik.values.startDate}
                                                onChange={(date) => searchFormik.setFieldValue('startDate', date)}
                                                dateFormat="dd-MM-yyyy"
                                                className="frmcntrl"
                                                showYearDropdown
                                                showMonthDropdown
                                                autoComplete="off"
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                                onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                                onClick={(e) => e.preventDefault()} // Preven
                                                placeholderText='From date'
                                            />
                                            {getSearchFormErrorMessage('startDate')}
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <DatePicker
                                                id="endDate"
                                                selected={searchFormik.values.endDate}
                                                onChange={(date) => searchFormik.setFieldValue('endDate', date)}
                                                dateFormat="dd-MM-yyyy"
                                                className="frmcntrl"
                                                showYearDropdown
                                                showMonthDropdown
                                                autoComplete="off"
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                                onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                                onClick={(e) => e.preventDefault()} // Preven
                                                placeholderText='To date'
                                            />
                                            {getSearchFormErrorMessage('endDate')}
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <select class="frmcntrl" style={{ color: "Gray" }} id='status' value={searchFormik.values.status} onChange={searchFormik.handleChange}>
                                                <option value=''>All</option>
                                                <option value="ORDERED">Ordered</option>
                                                <option value="INPROCESS">In Process (Approved)</option>
                                                <option value="DELIVERED">Delivered (Completed successfully)</option>
                                                <option value="PENDING">Failed</option>
                                                <option value="CANCELLED">Cancelled (Rejected)</option>
                                            </select>
                                        </div>
                                        <div class="col-md-3 col-sm-3 col-xs-4">
                                            <button class="btn login_white_btn" type="submit" disabled={areAllValuesBlank()}> Search <img src={longarrow} alt='' /></button>
                                        </div>
                                    </div>
                                </form>
                                <div className="row">
                                    <div className="col-md-12">
                                        {reportData === null || userType === 'RETAILER' || userType === 'SALES' ? '' :
                                            <button class="btn login_btn fa fa-file-excel-o pull-right mt-10 mb-10" type='submit' onClick={() => download(searchFormik.values)}> Export</button>}
                                        <div className="carrd1">
                                            <div className="card-body table-responsive custom-scrollbar">
                                                <table className="table table-bordered mytable mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Redemption/<br/> Reference ID</th>
                                                            <th>Redemption<br/> Type</th>
                                                            <th>Redeemed<br/> points</th>
                                                            <th>Amount/Voucher/Product</th>
                                                            <th>Redemption<br/>Status</th>
                                                            <th>Redemption<br/> Request Date</th>
                                                            {userType === 'HO' ? <th>Disbursement/<br/> Execution Date</th> : ''}
                                                            <th>Processing<br/> Result</th>
                                                            <th>Transaction ID<br/> (NEFT/UPI)</th>
                                                            <th>Failure reason<br/>(if any)</th>
                                                            <th>Finance dept.<br/> action</th>
                                                            <th>Remarks</th>
                                                            <th>Plumber Name</th>
                                                            <th>Plumber Unique ID*<br/> (Mobile Number)</th>
                                                            <th>Date of<br/> Registration</th>
                                                            {/* {userType === 'HO' ? <th>Tier/Level</th> : ''} */}
                                                            <th>Total<br/> Points</th>
                                                            <th>Balance<br/> Points</th>                                                
                                                            {userType === 'HO' ? <th>Delivery<br/> City</th> : ''}
                                                            {userType === 'HO' ? <th>Delivery<br/> Pin Code</th> : ''}
                                                            {userType === 'HO' ? <th>Delivery<br/> District</th> : ''}
                                                            {userType === 'HO' ? <th>Delivery<br/> State</th> : ''}
                                                            {userType === 'HO' ? <th>Delivery<br/> Address</th> : ''}
                                                            {userType === 'HO' ? <th>Pan<br/> Number</th> : ''}
                                                            {userType === 'HO' ? <th>KYC<br/> Status</th> : ''}                                                            
                                                            {userType === 'HO' ? <th>Bank A/c<br/> Number</th> : ''}
                                                            {userType === 'HO' ? <th>Account Holder's Name</th> : ''}
                                                            {/* {userType === 'HO' ? <th>Account Type</th> : ''} */}
                                                            {userType === 'HO' ? <th>Bank Name</th> : ''}
                                                            {userType === 'HO' ? <th>IFSC Code</th> : ''}
                                                            {userType === 'HO' ? <th>Branch</th> : ''}
                                                            {userType === 'HO' ? <th>UPI ID</th> : ''}
                                                            {userType === 'HO' ? <th>PayTm Number</th> : ''}
                                                            {userType === 'HO' ? <th>Blocked</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer Code</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer Name</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer Mobile</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer City</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer District</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer Pin Code</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer State</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer GST No</th> : ''}
                                                            <th>TSE Name</th>
                                                            <th>TSE Mobile Number</th>
                                                            <th>ASM Name</th>
                                                            <th>ASM Mobile Number</th>
                                                            <th>RSM Name</th>
                                                            <th>RSM Mobile Number</th>
                                                            <th>ZSM Name</th>
                                                            <th>ZSM Mobile No.</th>
                                                            
                                                            {/* <th>Zone</th> */}
                                                            
                                                            {/* <th>Zone State</th> */}
                                                            {/* <th>Zone Area Name</th> */}
                                                            
                                                            
                                                            
                                                            
                                                            
                                                        </tr>
                                                    </thead>
                                                    {reportData === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="11" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {reportData && reportData.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                const dateOfReg = item[23] ? moment(item[23]).format('DD-MM-YYYY') : ''
                                                                const redemptionDate = item[28] ? moment(item[28]).format('DD-MM-YYYY') : ''
                                                                const deliveryDate = item[33] ? moment(item[33]).format('DD-MM-YYYY') : ''
                                                                return (
                                                                    // item.orderItems.map((item2) => {
                                                                    //     return (
                                                                    <>
                                                                        <tr style={{ whiteSpace: 'nowrap' }}>
                                                                            <td>{serialNumber}</td>
                                                                            <td>{item[29]}</td>
                                                                            <td>{item[16] == null ? "N/A" : item[16]}</td>
                                                                            <td>{item[26]}</td>
                                                                            <td>{(item[16] == "UPI transfer" || item[16] == "Bank transfer") ? (item[26] * 0.02).toLocaleString('en-IN', { style: 'currency', currency: 'INR' }) : (item[16] == "EVG") ? item[46] : item[31] + '(' + item[30] + ')' || "N/A"}</td>
                                                                            <td>{item[32]}</td>
                                                                            <td>{redemptionDate}</td>
                                                                            <td>{item[50] == null ? "N/A" : moment(item[50]).format('DD-MM-YYYY')}</td>                                                                 
                                                                            <td>{item[47] == null ? "N/A" : item[47]}</td>
                                                                            <td>{item[51] == null ? "N/A" : item[51]}</td>
                                                                            <td>{item[48] == null ? "N/A" : item[48]}</td>
                                                                            <td>{!['APPROVED','REJECTED', 'PENDING'].includes(item[52]) ? "N/A" : item[52]}</td>

                                                                            <td>{item[49] == null ? "N/A" : item[49]}</td>
                                                                            <td>{item[14]}</td>
                                                                            <td>{item[15]}</td>
                                                                            <td>{dateOfReg}</td>
                                                                            {/* {userType === 'HO' ? <td></td> : ''} */}
                                                                            <td>{item[24]}</td>
                                                                            <td>{item[25]}</td>                                                                 
                                                                            {userType === 'HO' ? <td>{item[34]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[35]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[36]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[37]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[44]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[38]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[39]}</td> : ''}                                                                            
                                                                            {userType === 'HO' ? <td>{item[17]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[18]}</td> : ''}
                                                                            {/* {userType === 'HO' ? <td></td> : ''} */}
                                                                            {userType === 'HO' ? <td>{item[19]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[20]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[41]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[22]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[21]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[40] === true ? 'True' : 'False'}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[6]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[7]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[8]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[11]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[12]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[13]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[10]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[9]}</td> : ''}
                                                                            <td>{item[4]}</td>
                                                                            <td>{item[5]}</td>
                                                                            <td>{item[2]}</td>
                                                                            <td>{item[3]}</td>
                                                                            <td>{item[0]}</td>
                                                                            <td>{item[1]}</td>
                                                                            <td>{item[42]}</td>
                                                                            <td>{item[43]}</td>

                                                                            
                                                                            {/* <td></td> */}
                                                                            
                                                                            {/* <td></td> */}
                                                                            {/* <td></td> */}
                                                                            
                                                                            
                                                                           
                                                                            
                                                                            
                                                                        </tr>
                                                                    </>
                                                                    // )
                                                                    // })
                                                                )


                                                            })}

                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => {
                                                            setCurrentPage(page);
                                                            getReportData(filterData, page)
                                                        }} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>

            </section>
            <Footer />
        </>)
}
