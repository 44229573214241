import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Oval from "../Loader/CircleLoade";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Modal from "react-modal";
import axios from "axios";
import * as Constants from "../../../Constants";
import ResponsivePagination from "react-responsive-pagination";
import '../../style/style.css'
const containerStyle = {
  width: "100%",
  overflowX: "auto",
  whiteSpace: "nowrap",
  display: "flex",
  border: "1px solid #ccc",
};

const RedemptionApproval = () => {
  let token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [reportData, setReportData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState({
    modalFlag: false,
    action: "",
    modalData: {},
  });
  const [modalMessage, setModalMessage] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [commentsForSelectedItems, setCommentsForSelectedItems] = useState({});
  const [redemptionTypeFilter, setRedemptionTypeFilter] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [transStatus, setTransStatus] = useState(null);
  const [commentInput, setCommentInput] = useState("");

  useEffect(() => {
    fetchData(searchTerm, currentPage);
  }, [currentPage, redemptionTypeFilter]);

  const fetchData = async (search, pageNum) => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${Constants.baseUrl}redeem/catelog/orderAll`,
        {
          params: {
            sortAsc: false,
            limit: limit,
            page: pageNum,
            name: search,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
        }
      );

      const dt = response.data;

      if (dt.result === "success") {
        setReportData(dt?.output?.results);
        setTotalPages(dt?.output?.totalPages);

        // Apply redemption type filter if set
        if (redemptionTypeFilter !== "") {
          const updatedData = dt?.output?.results.filter(
            (item) => item.orderType === redemptionTypeFilter
          );
          setFilteredData(updatedData);
        } else {
          setFilteredData(dt?.output?.results);
        }
      } else {
        setReportData(null);
        setFilteredData([]);
        setTotalPages(0);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRedemptionType = (event) => {
    const type = event.target.value;

    // If no type is selected, reset filteredData to the original reportData
    if (type === "") {
      setFilteredData(reportData);
      setRedemptionTypeFilter(type);
      return;
    }

    // Filter the reportData based on the selected redemptionTypeFilter
    const updatedData = reportData.filter((item) => item.orderType === type);

    // Update state with the filtered data
    setFilteredData(updatedData);
    setRedemptionTypeFilter(type);
  };

  const handleSearchButtonClick = () => {
    if (searchTerm.trim().length > 0) {
      // Perform search functionality here
      fetchData(searchTerm, currentPage);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && searchTerm.trim().length > 0) {
      // Perform search functionality here
      fetchData(searchTerm, currentPage);
    } else if (event.key === 'Escape') {
      setSearchTerm('');
    }
  }

  const handleSearchTermChange = (e) => {
    const value = e.target.value;
    if (!value.length) {
      fetchData("", currentPage);
    }
    setSearchTerm(value);
  };

  const openModal = (action) => {
    setModalIsOpen((prev) => ({ ...prev, modalFlag: true, action: action }));
  };

  const closeModal = () => {
    setModalIsOpen((prev) => ({ ...prev, modalFlag: false }));
    setModalMessage("");
  };

  const handleBulkAction = (action, item) => {
    openModal(action, item);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const selectedIds = selectedItems.map((index) => filteredData[index].id);
      const selectedReferences = selectedItems.map(
        (index) => filteredData[index].referenceId
      );

      // Example of approving or rejecting multiple items
      // for (let i = 0; i < selectedIds.length; i++) {
      //   const id = selectedIds[i];
      //   const referenceId = selectedReferences[i];
      //   const action =
      //     filteredData[selectedItems[i]].status === "ORDERED"
      //       ? "APPROVE"
      //       : "REJECT";
      //   const status = action === "APPROVE" ? "Approved" : "Rejected";
      //   const comment = commentsForSelectedItems[id] || "";

      //   const requestBody = {
      //     orderId: id,
      //     referenceId: referenceId,
      //     remark: comment,
      //     status: status,
      //   };

      //   await axios
      //     .post(`${Constants.baseUrl}web/evg/verify`, requestBody, {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //         "Accept-Language": localStorage.getItem("langauge"),
      //         appVersion: Constants.av,
      //         platform: Constants.pt,
      //         company: Constants.company,
      //         program: Constants.program,
      //       },
      //     })
      //     .then((res) => {
      //       console.log("------Response----------", res);
      //       if (res.data?.result === "success") {
      //         setModalMessage(res.data?.msg);
      //         fetchData(searchTerm, currentPage); // Refresh data after successful action
      //       } else {
      //         setModalMessage(res.data?.msg);
      //       }
      //     });
      // }

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setModalMessage("An error occurred while processing the request.");
      setLoading(false);
    }
  };

  const handleCommentInputChange = (event, id) => {
    const { value } = event.target;
    setCommentsForSelectedItems({
      ...commentsForSelectedItems,
      [id]: value,
    });
  };

  const updateAction = async (action, data) => {
    console.log("204", action, data, commentInput);
    setModalIsOpen((prev) => ({ ...prev, modalFlag: false }));
    const requestBody = {
      orderId: data.id,
      referenceId: data.evgReferenceId,
      remark: commentInput,
      status: action,
    };
    console.log("payload", requestBody);
    const redemptionResp = await axios.post(
      `${Constants.baseUrl}web/evg/verify`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": localStorage.getItem("language"),
          appVersion: Constants.av,
          platform: Constants.pt,
          company: Constants.company,
          program: Constants.program,
        },
      }
    );
    console.log("redeResp", redemptionResp);
    // .then((res) => {
    if (redemptionResp.data.result == "success") {
      // openModal(redemptionResp.data.msg);
      fetchData("", currentPage);
      // Refresh data after successful approval
    }
    // });
  };

  // Function to format value as currency with INR symbol and two decimal places
const formatCurrency = (value) => {
  const formattedValue = new Intl.NumberFormat('en-IN', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  return `₹ ${formattedValue}`; // Add space between INR symbol and value
};

  return (
    <>
      {loading && <Oval />}
      <Header />
      <section className="blog-area pt182">
        <div className="container" style={{width:"100%"}}> 
          <div className="row">
            <div className="col-md-8 mr-2 tab">
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className="active">
                  <Link to="" role="tab" data-toggle="tab">
                    Redemption Approval
                  </Link>
                </li>
              </ul>
            </div>
            {/* Search input field */}
            <div className="col-md-3">
              <input
                type="text"
                className="form-control form-control-sm"
                style={{
                  fontSize: "1rem",
                  height: "3.1rem",
                  padding: "0.5rem",
                }}
                placeholder="Enter Mobile/Name..."
                value={searchTerm}
                onChange={handleSearchTermChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="col-md-1">
              <button
                className="btn btn-primary d-flex align-items-center justify-content-center ms-20"
                type="button"
                onClick={handleSearchButtonClick}
                style={{
                  fontSize: "1.1rem",
                  fontFamily: "Sans-serif",
                  backgroundColor: "#2187ab",
                  color: "#E1F2FE",
                }}
              >
                SEARCH
              </button>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-6 mt-10 ml-40"
              style={{ marginBottom: "10px", marginLeft: "46px" }}
            >
              <span style={{ fontSize: "17px", color: "#2187ab" }}>
                Redemption Type
              </span>
              <select
                id="redemptionType1"
                style={{
                  borderRadius: "20px",
                  marginLeft: "10px",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
                name="redemptionType1"
                onChange={handleChangeRedemptionType}
                value={redemptionTypeFilter}
              >
                <option value="">All</option>
                <option value="UPI transfer">UPI transfer</option>
                <option value="EVG">EVG</option>
                <option value="Bank transfer">Bank transfer</option>
                <option value="Catalogue">Merchandise</option>
              </select>
            </div>
          </div>
          <div className="card1">
            <div
              className="card-body table-responsive custom-scrollbar"
              style={containerStyle}
            >
              <table
                className="table table-bordered mytable mb_01 scroll-table"
                style={{ minWidth: "1500px" }}
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>ID</th>
                    <th>Type</th>
                    <th>Opening points balance</th>
                    <th>Points redeemed</th>
                    <th>Closing points balance</th>
                    <th>Redemption amount(₹)/Voucher name</th>
                    <th>Plumber Name</th>
                    <th>Plumber Mobile</th>
                    {/* <th>Plumber State</th>
                    <th>Plumber City</th> */}

                    <th>Approval status</th>
                    {/* <th>TSE name</th>
                    <th>TSE mobile</th>
                    <th>Pincode</th>
                    <th>Plumber registration date</th> */}
                    <th>Request date</th>
                    {/* <th>Invoice upload amount</th>
                    <th>Audited Points</th>
                    <th>Unaudited Points</th> */}
                    {/* <th>
                      Redemption points till date (Excluding current redemption)
                    </th>
                    <th>Balance points (after current redemption)</th>
                    <th>
                      Amount redemption till date in this month(invoice amount)
                    </th>
                    <th>
                      Amount redemption till date in this month(INR after 2%
                      conversion)
                    </th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.length < 1 || !filteredData ? (
                    <tr>
                      <td
                        colSpan="21"
                        style={{ textAlign: "center", fontSize: "16px" }}
                      >
                        No record found!
                      </td>
                    </tr>
                  ) : (
                    filteredData.map((item, index) => {
                      const serialNumber =
                        (currentPage - 1) * limit + index + 1;
                      return (
                        <tr key={index}>
                          <td>{serialNumber}</td>
                          <td>{item.id}</td>
                          {/* <td>{item.orderType}</td> */}
                          <td>
                            {
                              item.orderType == "UPI transfer" ?
                              (<span>UPI</span>) :
                              item.orderType == "Bank transfer" ?
                              (<span>NEFT</span>) :
                              item.orderType == "Catalogue" ?
                              (<span>Merch</span>) :
                              (<span>Voucher</span>)
                            }
                          </td>
                          <td>{
                            item.openingPointsBalance == null ?
                            (item.user.points + item.points) :
                            (item.openingPointsBalance)
                          }</td>
                          {/* <td>{item.user.points + item.points}</td> */}
                          <td>{item.points}</td>
                          <td>{
                            item.closingPointsBalance == null ?
                            (item.user.points) :
                            (item.closingPointsBalance)
                            }</td>
                          {/* <td>{item.user.points}</td> */}
                          <td>{ item.orderType == "EVG" ? item.evgProductName : formatCurrency(item.points * 0.02) }</td>

                          <td>{item.user.name}</td>
                          <td>{item.user.mobile}</td>
                          {/* <td>{item.user.addressWork.workState}</td>
                          <td>{item.user.addressWork.workCity}</td> */}

                          <td>
                            {item.adminApprovalStatus === "PENDING" ? (
                              <span style={{ color: "orange" }}>
                                Pending approval
                              </span>
                            ) : item.adminApprovalStatus === "APPROVED" ? (
                              <span style={{ color: "green" }}>APPROVED</span>
                            ) : (
                              <span style={{ color: "red" }}>
                                REJECTED
                              </span>
                            )}
                          </td>
                          {/* <td>{item.user.parentName}</td>
                          <td>{item.user.parentMobile}</td>
                          <td>{item.user.addressWork.workPincode}</td>
                          <td>{item.user.createDateTime}</td> */}
                          <td>{item.createDateTime}</td>
                          {/* <td>{item.invoiceUploadAmount}</td>
                          <td>{item.user.points}</td>
                          <td>{item.user.unAuditedPoints}</td>
                          <td>{item.user.totalPoints}</td>
                          <td>{item.user.points}</td>
                          <td>{item.amountRedemptionTillDateInvoiceAmount}</td>
                          <td>{item.amountRedemptionTillDateConverted}</td> */}
                          <td>
                            {item.adminApprovalStatus === "PENDING" && (
                              <>
                                <button
                                  className="btn btn-success me-2"
                                  onClick={() => {
                                    setCommentInput("");
                                    setModalIsOpen((prev) => ({
                                      ...prev,
                                      modalFlag: true,
                                      modalData: item,
                                      action: "APPROVED",
                                    }));
                                  }}
                                  style={{
                                    fontSize: "10px",
                                    backgroundColor: "#2187ab",
                                    borderColor: "#28a745",
                                    color: "#fff",
                                    padding: "8px 16px",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                    borderWidth: "0px",
                                    marginRight: "10px",
                                  }}
                                >
                                  Approve
                                </button>
                                <button
                                  className="btn btn-danger"
                                  onClick={() => {
                                    setCommentInput("");
                                    setModalIsOpen((prev) => ({
                                      ...prev,
                                      modalFlag: true,
                                      modalData: item,
                                      action: "REJECTED",
                                    }));
                                  }}
                                  style={{
                                    fontSize: "10px",
                                    color: "#fff",
                                    padding: "8px 18px",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                    backgroundColor: "#2187ab",
                                    borderWidth: "0px",
                                  }}
                                >
                                  Reject
                                </button>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {totalPages > 1 && (
            <div className="pagination-container">
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={(page) => {
                  setCurrentPage(page);
                  fetchData(searchTerm, page);
                }}
              />
            </div>
          )}
        </div>
      </section>
      <Footer />

      <Modal
        isOpen={modalIsOpen.modalFlag}
        onRequestClose={closeModal}
        contentLabel="Confirmation Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "50%",
            width: "35%",
            padding: "20px",
            borderRadius: "8px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "#2196f3",
          },
        }}
      >
        <h3>{modalMessage}</h3>
        <input
          type="text"
          value={commentInput}
          onChange={(e) => setCommentInput(e.target.value)}
          placeholder="Enter comments"
          style={{
            margin: "10px auto",
            padding: "12px",
            width: "80%",
            borderRadius: "6px",
            border: "1px solid #ccc",
            fontSize: "16px",
            color: "#333",
            backgroundColor: "#f5f5f5",
            outline: "none",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            transition:
              "border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
            fontFamily: "'Roboto', sans-serif",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <button
            className="btn btn-primary"
            onClick={() => {
              updateAction(modalIsOpen.action, modalIsOpen.modalData);
              // Clear comment input after submission
            }}
            disabled={loading}
            style={{
              padding: "12px 24px",
              borderRadius: "8px",
              border: "none",
              fontSize: "1.1rem",
              fontWeight: "bold",
              color: "#ffffff",
              backgroundColor: "#2187ab",
              cursor: "pointer",
              boxShadow: "0 4px 6px rgba(33, 135, 171, 0.1)",
              transition: "background-color 0.3s ease-in-out",
              marginRight: "10px",
            }}
          >
            SUBMIT
          </button>
          <button
            className="btn btn-danger"
            onClick={closeModal}
            style={{
              padding: "12px 24px",
              borderRadius: "8px",
              border: "none",
              fontSize: "1.1rem",
              fontWeight: "bold",
              color: "#ffffff",
              backgroundColor: "#2187ab",
              cursor: "pointer",
              boxShadow: "0 4px 6px rgba(220, 53, 69, 0.1)",
              transition: "background-color 0.3s ease-in-out",
            }}
          >
            CLOSE
          </button>
        </div>
      </Modal>
    </>
  );
};

export default RedemptionApproval;
